<template>
  <div class="user">
    <!-- 面包屑导航 -->
    <el-card>
      <el-tabs>
        <el-tab-pane label="基础信息">
          <el-form :model="form" size="small" label-position="left">
            <el-form-item
              label="保养详情"
              :label-width="formLabelWidth"
              prop="info"
            >
              <el-input v-model="form.info" placeholder="保养名称"></el-input>
            </el-form-item>
            <el-form-item label="保养周期" :label-width="formLabelWidth">
              <el-input
                v-model="form.fate"
                placeholder="保养周期（天数）"
              ></el-input>
            </el-form-item>
            <el-form-item label="使用注意事项" :label-width="formLabelWidth">
              <el-input
                v-model="form.matters"
                placeholder="使用注意事项"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>

        <el-tab-pane label="选择信息">
          <el-form :model="form" size="small" label-position="left">
            <el-form-item label="供应商ID" :label-width="formLabelWidth">
              <el-select
                v-model="form.v_id"
                style="width: 100%"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in options3"
                  :key="item.id"
                  :label="item.dealer_name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="选择分类" :label-width="formLabelWidth">
              <el-cascader
                :options="options"
                :props="optionProps"
                v-model="form.cc_id"
                filterable
                clearable
              >
              </el-cascader>
            </el-form-item>
            <el-form-item label="选择商品" :label-width="formLabelWidth">
              <el-cascader
                :options="options2"
                :props="optionProps"
                v-model="form.mp_id"
                filterable
                clearable
              >
              </el-cascader>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="保养插图">
          <el-upload
            :headers="token"
            accept=".jpg,.png"
            :on-remove="imgRemove"
            :limit="3"
            :action="imgUrl"
            list-type="picture"
            :on-exceed="exceed"
            :on-success="imgSuccess"
            name="image"
            :file-list="fileList"
          >
            <el-button size="small" type="primary"
              >上传插图</el-button
            ></el-upload
          >
        </el-tab-pane>
        <el-tab-pane label="确认添加">
          <el-button @click="setAdd" type="primary" size="medium"
            >确认添加</el-button
          >
        </el-tab-pane>
      </el-tabs>
      <!-- 头 -->
    </el-card>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  getAdd,
  getOption,
  getOption2,
  getOption3,
} from "@/api/psi/service.js";
export default {
  //import引入的组件需要注入到对象中才能使用
  data() {
    //这里存放数据
    return {
      token: {
        "Accept-Token": window.sessionStorage.getItem("token"),
      },
      options: [],
      options2: [],
      options3: [],
      optionProps: {
        value: "id",
        label: "name",
        children: "sub",
      },
      fileList: [],
      //总页数
      exp: {
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        info: "",
        page: 1,
        list_rows: 4,
      },
      //导航
      nav: [],
      options: [],
      //单选列表
      radiolist: [
        {
          id: 1,
          name: "一级",
        },
        {
          id: 2,
          name: "二级",
        },
      ],
      getNav: [],
      //即将删除的信息
      userdelinfo: {},
      info: {},
      // 添加信息表单
      form: {
        info: "",
        v_id: "",
        fate: "",
        matters: "",
        img: [],
        cc_id: [],
        mp_id: [],
      },
      //表单名称长度
      formLabelWidth: "120px",
      //控制弹窗删除
      dialogVisible: false,
      //控制弹窗--新增修改
      dialogFormVisible: false,
      //搜索框
      inputSearch: "",
      //表格数据
      tableData: [],
    };
  },
  //监听属性 类似于data概念
  computed: {
    imgUrl() {
      return this.$store.state.imgUrl;
    },
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    exceed() {
      this.$message({
        message: "数量超出",
        type: "warning",
      });
    },
    //导入图片事件
    imgSuccess(response, file, fileList) {
      console.log(response);
      this.form.img.push(response.data.url);
    },

    //移除图片的事件
    imgRemove(file, fileList) {
      this.form.img = [];
      fileList.forEach((item) => {
        this.form.img.push(item.response.data.url);
      });
      console.log(this.form);
    },
    exceed() {
      this.$message({
        message: "数量超出",
        type: "warning",
      });
    },
    //移除图片的事件
    imgRemove() {
      this.form.thumb = "";
      console.log(this.form);
    },
    clear() {
      this.form = {};
    },
    change(val) {
      console.log(val);
    },
    goBack() {
      this.$router.go(-1);
    },
    //搜索事件
    search(e) {
      console.log("搜索信息: " + e);
    },
    //添加事件--提交事件
    async setAdd(e) {
      console.log(this.form);
      let info = await getAdd(this.form);
      this.judge(info);
    },
    judge(info) {
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: "success",
        });
        this.$router.push("/service");
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: "error",
        });
        return;
      }
    },
    async setOption() {
      let info = await getOption();
      let info2 = await getOption2();
      let info3 = await getOption3();
      this.options = info.data.data;
      this.options2 = info2.data.data;
      this.options3 = info3.data.data;
    },

    //添加事件 退出弹窗
    userClose(e) {
      this.form = {};
      console.log("close");
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {
    this.setOption();
    this.getNav = this.$getNavn("/service");
  }, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
};
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}
.tab-top {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 10px;
}
.tab-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  margin-right: 10px;
}
.tab-info > span {
  display: flex;
  width: 80px;
}
.info-bt {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
</style>